<template>
  <Modal
    :header="trans('MODAL_TITLE:LocationSelection')"
    variant="map"
    hide-footer
    :show-modal="showModal"
    @clicked="$emit('clicked')"
  >
    <template v-slot:content>
      <div class="w-full flex flex-row mt-3 px-3 lg:px-6 ">
        <div class="w-1/2 flex flex-row gap-3 items-center" :class="{'opacity-25':legendADisabled}">
          <div class="w-6 h-6 bg-dodgerBlue bg-opacity-50 border-2 border-dodgerBlue rounded-full"></div>
          <div class="text-sm">{{ trans(legendA) }}</div>
        </div>
        <div class="w-1/2 flex flex-row gap-3 items-center">
          <div class="w-6 h-6 bg-redAccent bg-opacity-50 border-2 border-redAccent rounded-full"></div>
          <div class="text-sm">{{ trans(legendB) }}</div>
        </div>
      </div>
      <div
        v-if="allLocationsData !== null && allLocationsData.length > 0"
        id="mapContainer"
        class="mt-3 mx-3 lg:mx-6 mb-3 lg:mb-6 border border-grayed rounded lg:w-660 lg:h-660"
        :class="[getHeightOfScreenWidth(1,48), getWidth('Map')]"
      >

      </div>
      <div v-else
        class="bg-red m-3 lg:m-6 border border-grayed rounded lg:w-660 lg:h-sidebar flex items-center justify-center"
        :class="[getHeightOfScreenWidth(1,48), getWidth('Map')]"
      >
        <div class="w-full flex flex-col gap-3 items-center justify-center">
          <Loader
            class="w-min mx-auto"
            :class="getHeightOfScreenWidth(1,48)"
            no-border
          ></Loader>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Loader from "@/views/Loader";
import Modal from "@/utils/modal/Modal.vue";
import {getWidth, getHeightOfScreenWidth} from "@/common/helpers/utils";

export default {
  name: "ModalMap",
  components:{Modal, Loader},
  props: {
    showModal:Boolean,
    allLocationsData: Array,
    legendA: String,
    legendADisabled: Boolean,
    legendB: String,
    legendBDisabled: Boolean,
  },
  data(){
    return {
      getHeightOfScreenWidth: getHeightOfScreenWidth,
      getWidth:getWidth,
    }
  }
}
</script>

<style  lang="scss">
  .leaflet-control-scale {
    color: #000000;
    font-weight: bold
  }
  .leaflet-control-scale-line div {
    background-color: #000000;
  }
  .leaflet-control-scale-line {
    background-color: white;
    border-color: #000000;
  }

</style>