<template>
  <div class="leading-normal" v-if="render">
    <div class="flex flex-col items-start space-y-3 mb-3 w-full">
      <Autocomplete
        :selectedItem="userDataEdit.location.descRegion"
        :placeholder="trans('PLACEHOLDER:StartTypingCityName')"
        type="location"
        @result-selected="acceptLocation"
        ref="autocomplete"
      />
      <div class="w-full grid grid-cols-3">
        <CheckboxField
          v-model="userDataEdit.location.isLocationVisible"
          :label="trans('LABEL:ShowLocation')"
        />
        <div class="text-center">
          <Button
            class="text-s"
            :text="trans('BUTTON:MapChoose')"
            variant="link"
            @clicked="openMapModal(userData, userDataEdit.location.id)"
          />
        </div>
      </div>
    </div>

    <ModalMap
      v-if="isShowMapModal"
      :show-modal="isShowMapModal"
      :all-locations-data="allLocationsData"
      legend-a="LABEL:LocationCurrent"
      :legend-a-disabled="userData.location.id<1"
      legend-b="LABEL:LocationNew"
      @clicked="closeMapModal"
    />
  </div>

</template>

<script>
import SelectField from "@/utils/crud/components/field-types/Select";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import CheckboxField from "@/utils/crud/components/field-types/Checkbox";
import TextField from "@/utils/crud/components/field-types/Text.vue";
import Autocomplete from "@/utils/crud/components/field-types/Autocomplete.vue";
import Button from "@/utils/crud/components/Button";
import ModalMap from "@/views/content/components/ModalMap.vue";
import {
  goToSpecificRoute,
  getHeightOfScreenWidth,
  getWidth,
  fitMapToShowLocations,
  calculateMarkerStyle
} from "@/common/helpers/utils";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  name: "LocationForm",
  components: {Autocomplete, Button, TextField, CheckboxField, SelectField, ModalMap},
  props: {
    userData: Object,
    sendRequest: Boolean,
  },
  data() {
    return {
      userDataEdit: null,
      render: false,
      searchLocation: null,
      isShowMapModal: false,
      map:null,
      goToSpecificRoute: goToSpecificRoute,
      getHeightOfScreenWidth: getHeightOfScreenWidth,
      getWidth: getWidth,
      fitMapToShowLocations: fitMapToShowLocations,
      calculateMarkerStyle: calculateMarkerStyle,
    }
  },

  watch: {
    userDataEdit(val) {
      if(val) {
        this.render = true;
      }
    },
    sendRequest(val) {
      if(val) {
        this.setSendRequestStatus(false);
        this.sendEditRequest({
          location_id: this.userDataEdit.location.id,
          is_location_visible: !!this.userDataEdit.location.isLocationVisible
        })
      }
    }
  },
  methods: {
    ...mapMutations('profile', ['setSendRequestStatus']),
    ...mapActions('profile', ['sendEditRequest']),
    ...mapActions('dictionaries', ['fetchAllLocationsData']),

    acceptLocation(e) {
      this.userDataEdit.location.id = e.id;
    },

    openMapModal(userData, searchLocation) {
      this.isShowMapModal = true;

      // Upewnij się, że modal jest otwarty zanim zainicjalizujesz mapę
      this.$nextTick(() => {
        // Sprawdź, czy kontener mapy istnieje
        if (document.getElementById('mapContainer')) {

          let map = this.map;
          // Zmienna do przechowywania markerow z mapy do przeskalowywania
          const markers = [];
          let userMarker = null;
          // Warstwa na lokalizacje uzytkownika i wybrana lokalizacje
          let topMarkersLayer = L.layerGroup();
          let mapZoomMin = 6;
          let mapZoomDefault = 8;
          let mapZoomMax = 12;
          // Kolory markerow
          let markerColorDefault = '#888888';
          let markerColorUser = '#1e90ff';
          let markerColorSelected = '#800000';
          // Srodek mapy w srodku Polski
          let centerLatitude = '52.0651';
          let centerLongitude = '19.4794';
          // Srodek mapy w lokalizacji uzytkownika (jesli jest)
          if (userData.location.id > 0) {
            centerLatitude = userData.location.latitude;
            centerLongitude = userData.location.longitude;
          }
          if (userData.location.id > 0) {
            map = L.map('mapContainer').setView([centerLatitude, centerLongitude], mapZoomDefault);
          } else {
            map = L.map('mapContainer').setView([centerLatitude, centerLongitude], 6);
          }
          // Dodanie skali
          L.control.scale({
            position: 'bottomright', metric: true, imperial: false, maxWidth: 200
          }).addTo(map);
          // Dodanie warstwy OpenStreetMap
          L.tileLayer(
              'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
              {minZoom: mapZoomMin, maxZoom: mapZoomMax}
          ).addTo(map);
          // Wylaczenie krzyzyka z Popupow
          L.Popup.prototype.options.closeButton = false;

          const canvasRenderer = L.canvas();
          let searchLocationLatLng = null;
          let userLocationLatLng = null;
          if (userData.location.id > 0) {
            userLocationLatLng = L.marker([userData.location.latitude,userData.location.longitude]);
          }

          // --------------------------------------- DLA KAZDEGO PUNKTU NA MAPIE ---------------------------------------
          this.allLocationsData.forEach(point => {
            const marker = L.circleMarker(
                [point.latitude, point.longitude],
                {renderer: canvasRenderer}
            ).addTo(map);
            let distance = 0
            if (userData.location.id > 0) {
              distance = Math.round(
                L.latLng([point.latitude, point.longitude]).distanceTo(userLocationLatLng.getLatLng()) / 1000
              );
            }

            marker.bindPopup(`
              <div class="flex flex-col px-3 py-1.5">
                <div class="w-full flex flex-row gap-1.5">
                  <div class="mont-font font-semibold text-sm">${point.name}</div>
                  <div class="mont-font font-medium text-grayed text-s">${distance>0?'('+distance+' km)':''}</div>
                </div>
                <div class="w-full mont-font font-medium text-grayed text-s">${point.region}</div>
              </div>
            `);

            marker.on('click', function() {
              this.locationSelectedOnMap(point);
            }.bind(this));

            marker.on('mouseover', function() {
              if (point.id !== userData.location.id && point.id !== searchLocation) {
                calculateMarkerStyle(map, marker, markerColorSelected);
              }
              marker.openPopup();
            });

            marker.on('mouseout', function() {
              if (point.id !== userData.location.id && point.id !== searchLocation) {
                calculateMarkerStyle(map, marker, markerColorDefault);
              }
              marker.closePopup();
            });

            if (point.id === userData.location.id) {
              // Lokalizacja uzytkownika
              calculateMarkerStyle(map, marker, markerColorUser);
              userMarker = marker;
            } else if (point.id === searchLocation) {
              // Wybrana lokalizacja
              searchLocationLatLng = L.marker([point.latitude, point.longitude]);
              calculateMarkerStyle(map, marker, markerColorSelected);
            }
            else {
              // Kazda lokalizacja
              calculateMarkerStyle(map, marker, markerColorDefault);
            }

            if (userData.location.id === point.id || searchLocation === point.id) {
              // Dodanie lokalizacji uzytkownika i wybrnaej lokalizacji do dodatkowej warstwy
              marker.addTo(topMarkersLayer);
            } else {
              marker.addTo(map);
            }
            // Dodanie markera do listy markerow
            markers.push(marker);
          });
          //----------------------------------------------- KONIEC PETLI -----------------------------------------------

          // Dodanie dodatkowej warstwy do mapy
          topMarkersLayer.addTo(map);
          userMarker.openPopup();

          // Przeskalowanie mapy, jesli jest wybrana lokalizacja
          if (userData.location.id > 0) {
            fitMapToShowLocations(map, userLocationLatLng, searchLocationLatLng);
          }

          // Obsluga zoomowania mapy
          map.on('zoomend', function() {
            markers.forEach(m => {
              calculateMarkerStyle(map, m, m.options.color, m.options.fillColor)
            });
          });
        }
      });
    },

    locationSelected(location) {
      this.userSearchLocation = location.id;
      this.userDataEdit.location = location;
      this.$refs.autocomplete.search = location.descRegion;
      this.$refs.autocomplete.manageClick(location);
    },

    locationSelectedOnMap(location) {
      this.locationSelected(location)
      this.closeMapModal();
    },

    closeMapModal() {
      this.isShowMapModal = false;
      if (this.map) {
        map.eachLayer(function(m) {
          m.off();
          map.removeLayer(m);
        });
        this.map.remove();
        this.map = null;
      }
    },

    async loadAllLocationsData() {
      try {
        // Czekaj na zakończenie pobierania danych
        await this.fetchAllLocationsData();
        console.log('Pobrane dane lokalizacji.');
      } catch (error) {
        console.error('Błąd podczas pobierania danych lokalizacji:', error);
      }
    },

  },
  computed: {
    ...mapGetters('dictionaries', ['allLocationsData'])
  },
  mounted() {
    this.userDataEdit = this.userData;
    this.loadAllLocationsData();
    if(this.userDataEdit.location.id) {
      this.searchLocation = this.userDataEdit.location.descRegion;
    }
  },
}
</script>
<style lang="scss">
.leaflet-popup-content-wrapper {
  padding: 0;
  margin:0;
  border-radius: 0.25rem;
}
.leaflet-popup-content{
  padding:0;
  margin: 0;
}
</style>